import { defineStore } from 'pinia';
import axios from "axios";
import { environment } from 'Common/environments/environment';

export const useApiKeyStore = defineStore('apiKeyStore', {
    state: () => {
        return {
            apiKeys: [],
            stats: null,
        }
    },
    getters: {

    },
    actions: {
        getApiKeyStats() {
            return axios.get(environment.apiKeysServiceUrl + '/api-keys/stats').then(response => {
                if (!response.data.success) return Promise.reject(response.data);
                return this.stats = (response.data.data || {});
            });
        },
        loadApiKeyById(apiKeyExternalId) {
            return axios.get(environment.apiKeysServiceUrl + `/api-keys/${apiKeyExternalId}`).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || {};
            });
        },
        loadApiKeys(filter = {}) {
            return axios.get(environment.apiKeysServiceUrl + `/api-keys/query`, {
                params: {
                    ...filter
                }
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                const data = response.data.data.items || [];
                this.apiKeys = data;
                return data;
            });
        },
        loadReport() {
            return axios.post(environment.apiKeysServiceUrl + '/api-keys/report', {
                data: {}
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return response.data.data || [];
            });
        },
        addApiKey(data) {
            return axios.post(environment.apiKeysServiceUrl + '/api-keys', {
                data
            }).then((response) => {
                if (!response.data?.success) {
                    return Promise.reject(response.data);
                }
                return response.data.data || {};
            });
        },
        editApiKey(data) {
            return axios.put(environment.apiKeysServiceUrl + `/api-keys/${data.apiKeyExternalId}`, {
                data
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return data;
            });
        },
        updateWhiteList(query) {
            return axios.post(environment.apiKeysServiceUrl + '/api-keys/updateWhiteList', {
                data: query
            }).then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                return query;
            });
        },
        deleteApiKey(apiKeyExternalId) {
            return axios
              .delete(environment.apiKeysServiceUrl + `/api-keys/${apiKeyExternalId}`)
              .then((response) => {
                if (!response.data.success) return Promise.reject(response.data);
                this.apiKeys = this.apiKeys.filter((item) => item.apiKeyExternalId !== apiKeyExternalId);
            });
        },
        getApiKeyByExternalId(apiKeyExternalId){
            return this.apiKeys.find((item) => item.apiKeyExternalId === apiKeyExternalId);
        }
    }
})
